<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card style="padding: 10px">
          <b-row>
            <b-col
              style="display: flex; justify-content: center"
              cols="12"
            >
              <h2
                style="font-weight: bold"
                class="text-primary"
              >
                REPORTE DE DAÑOS POR ACCIDENTE
              </h2>
            </b-col>
          </b-row>
          <b-tabs
            fill
          >
            <b-tab
              title="Datos del Accidente"
              active
            >
              <b-form class="mb-1">
                <b-row>
                  <b-col cols="7">
                    <b-card>
                      <b-row>
                        <b-col
                          style="display: flex; justify-content: center"
                          cols="12"
                        >
                          <h3
                            style="font-weight: bold; font-size: 26px"
                            class="text-primary text-uppercase"
                          >
                            INFORMACIÓN DEL RESPONSABLE DEL ACCIDENTE
                          </h3>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Nombre del Responsable
                            </h6>
                            <b-form-input
                              v-model="datosAccidente.nombre"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="6"
                        >
                          <b-form-group>
                            <h6 class="text-primary">
                              DPI
                            </h6>
                            <cleave
                              v-model="datosAccidente.dpi"
                              class="form-control"
                              :raw="false"
                              :options="options.dpi"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Número de Licencia
                            </h6>
                            <b-form-input
                              v-model="datosAccidente.licencia"
                              :disabled="editable"
                              type="number"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Teléfono
                            </h6>
                            <b-form-input
                              v-model="datosAccidente.telefono"
                              :disabled="editable"
                              type="number"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group>
                            <h6 class="text-primary">
                              Dirección
                            </h6>
                            <b-form-textarea
                              v-model="datosAccidente.direccion"
                              :disabled="editable"
                              no-resize
                              rows="2"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- BOTONES -->
                        <b-col
                          v-if="editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="changeEditable"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Editar</span>
                          </b-button>
                        </b-col>
                        <b-col
                          v-if="!editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="guardarDetalles"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ getTextButton() }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            type="reset"
                            class="mt-2"
                            @click.prevent="cancel"
                          >
                            <feather-icon
                              icon="SlashIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Cancelar</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col
                    style="display: flex; justify-content: center"
                    cols="5"
                  >
                    <b-card>
                      <h3
                        style="font-weight: bold; font-size: 20px; display: flex; justify-content: center"
                        class="text-primary text-uppercase"
                      >
                        Fotografías {{ coordinacion.tipoAccidente ? coordinacion.tipoAccidente.nombre : '' }}
                      </h3>
                      <hr>
                      <b-carousel
                        id="carousel-caption"
                        controls
                        indicators
                        height="380"
                        :interval="4000"
                      >
                        <template v-for="(item, index) in coordinacion.seguimiento">
                          <b-carousel-slide :key="index">
                            <template #img>
                              <img
                                style="max-height: 380px; max-width: 500px; min-height: 380px; min-width: 500px"
                                class="d-block img-fluid w-100 img"
                                :src="item.url"
                                :alt="item.name"
                              >
                            </template>
                          </b-carousel-slide>
                        </template>
                      </b-carousel>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Datos del Vehiculo">
              <b-form class="mb-1">
                <b-row>
                  <b-col cols="7">
                    <b-card>
                      <b-row>
                        <b-col
                          style="display: flex; justify-content: center"
                          cols="12"
                        >
                          <h3
                            style="font-weight: bold; font-size: 26px"
                            class="text-primary text-uppercase"
                          >
                            DATOS DEL VEHÍCULO ACCIDENTADO
                          </h3>
                          <br>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Número de Placa
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.placa"
                              :disabled="editable"
                              maxlength="7"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Nombre del Propietario
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.propietario"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Tipo de Vehiculo
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.tipo"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Marca
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.marca"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Color
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.color"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Modelo
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.modelo"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Cuenta con Seguro
                            </h6>
                            <v-select
                              v-model="datosVehiculo.seguro"
                              :disabled="editable"
                              label="nombre"
                              :options="opcionesSiNo"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Empresa Aseguradora
                            </h6>
                            <b-form-input
                              v-model="datosVehiculo.aseguradora"
                              :disabled="!editableAseguradora"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- BOTONES -->
                        <b-col
                          v-if="editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="changeEditable"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Editar</span>
                          </b-button>
                        </b-col>
                        <b-col
                          v-if="!editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="guardarDetalles"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ getTextButton() }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            type="reset"
                            class="mt-2"
                            @click.prevent="cancel"
                          >
                            <feather-icon
                              icon="SlashIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Cancelar</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col cols="5">
                    <b-card>
                      <h3
                        style="font-weight: bold; font-size: 20px; display: flex; justify-content: center"
                        class="text-primary text-uppercase"
                      >
                        Fotografías {{ coordinacion.tipoAccidente ? coordinacion.tipoAccidente.nombre : '' }}
                      </h3>
                      <hr>
                      <b-carousel
                        id="carousel-caption"
                        controls
                        indicators
                        height="380"
                        :interval="4000"
                      >
                        <template v-for="(item, index) in coordinacion.seguimiento">
                          <b-carousel-slide :key="index">
                            <template #img>
                              <img
                                style="max-height: 380px; max-width: 500px; min-height: 380px; min-width: 500px"
                                class="d-block img-fluid w-100 img"
                                :src="item.url"
                                :alt="item.name"
                              >
                            </template>
                          </b-carousel-slide>
                        </template>
                      </b-carousel>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab
              title="Visualización de Datos Ingresados"
            >
              <!--div>
                <coordinacion-aseguradora-formulario-vue
                  :cargar-coordinacion="coordinacion"
                  :campos-deshabilitados="true"
                />
              </div-->
              <div>
                <componente-visualizacion
                  :campos-deshabilitados="false"
                  :coordinacion="coordinacion"
                />
              </div>
            </b-tab>
            <b-tab title="Datos Adicionales">
              <b-form class="mb-1">
                <b-row>
                  <b-col cols="7">
                    <b-card>
                      <b-row>
                        <b-col
                          style="display: flex; justify-content: center"
                          cols="12"
                        >
                          <h3
                            style="font-weight: bold; font-size: 26px"
                            class="text-primary text-uppercase"
                          >
                            INFORMACIÓN ADICIONAL
                          </h3>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Matricula de Poste
                            </h6>
                            <b-form-input
                              v-model="coordinacion.posteReferencia"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Código de Lámpara
                            </h6>
                            <b-form-input
                              v-model="datosAdicionales.codigoLampara"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group>
                            <h6 class="text-primary">
                              Dirección del Accidente
                            </h6>
                            <b-form-textarea
                              v-model="datosAdicionales.direccion"
                              :disabled="editable"
                              no-resize
                              rows="2"
                            />
                          </b-form-group>
                          <hr>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Nombre de Corredor de Seguros
                            </h6>
                            <b-form-input
                              v-model="coordinacion.nombreAjustador"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Hora de Llegada
                            </h6>
                            <cleave
                              v-model="datosAdicionales.corredorSeguros.horaLlegada"
                              class="form-control"
                              :raw="false"
                              :options="options.clock"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Nombre de Personal de APVN
                            </h6>
                            <b-form-input
                              v-model="datosAdicionales.apvnsaPersonal.nombre"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Hora de Llegada
                            </h6>
                            <cleave
                              v-model="datosAdicionales.apvnsaPersonal.horaLlegada"
                              class="form-control"
                              :raw="false"
                              :options="options.clock"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Nombre de Personal de LUMINATA
                            </h6>
                            <b-form-input
                              v-model="datosAdicionales.luminataPersonal.nombre"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <h6 class="text-primary">
                              Hora de Llegada
                            </h6>
                            <cleave
                              v-model="datosAdicionales.luminataPersonal.horaLlegada"
                              class="form-control"
                              :raw="false"
                              :options="options.clock"
                              :disabled="editable"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- BOTONES -->
                        <b-col
                          v-if="editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="changeEditable"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Editar</span>
                          </b-button>
                        </b-col>
                        <b-col
                          v-if="!editable"
                          cols="12"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-2 mr-1"
                            @click="guardarDetalles"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ getTextButton() }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            type="reset"
                            class="mt-2"
                            @click.prevent="cancel"
                          >
                            <feather-icon
                              icon="SlashIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Cancelar</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col
                    style="display: flex; justify-content: center"
                    cols="5"
                  >
                    <b-card>
                      <h3
                        style="font-weight: bold; font-size: 20px; display: flex; justify-content: center"
                        class="text-primary text-uppercase"
                      >
                        Fotografías {{ coordinacion.tipoAccidente ? coordinacion.tipoAccidente.nombre : '' }}
                      </h3>
                      <hr>
                      <b-carousel
                        id="carousel-caption"
                        controls
                        indicators
                        height="380"
                        :interval="4000"
                      >
                        <template v-for="(item, index) in coordinacion.seguimiento">
                          <b-carousel-slide :key="index">
                            <template #img>
                              <img
                                style="max-height: 380px; max-width: 500px; min-height: 380px; min-width: 500px"
                                class="d-block img-fluid w-100 img"
                                :src="item.url"
                                :alt="item.name"
                              >
                            </template>
                          </b-carousel-slide>
                        </template>
                      </b-carousel>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Control y Orden de Seguimiento">
              <b-form class="mb-1">
                <b-card>
                  <b-row>
                    <b-col
                      style="display: flex; justify-content: center"
                      cols="6"
                    >
                      <h3
                        style="font-weight: bold; font-size: 26px"
                        class="text-primary text-uppercase"
                      >
                        Control y Orden de Seguimiento
                      </h3>
                    </b-col>
                    <b-col
                      style="display: flex; justify-content: center"
                      cols="6"
                    >
                      <h3
                        style="font-weight: bold; font-size: 26px"
                        class="text-primary text-uppercase"
                      >
                        Fecha de entrega
                      </h3>
                    </b-col>
                  </b-row>
                  <hr>
                  <table class="tablaa">
                    <tbody
                      class="tablabody"
                    >
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="10">
                              <h6 class="text-primary">
                                Fotografía o Copia de Informe Accidente (Seguro)
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.fotoSeguro"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Asistente de Accidente
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.asistenteSeguro"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :disabled="!controlSeguimiento.fotoSeguro"
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.asistenteSeguro"
                                      show-decade-nav
                                      :disabled="!controlSeguimiento.fotoSeguro"
                                      button-only
                                      size="sm"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <h6 class="text-primary">
                                Informe Técnico de Accidente
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.informeAccidente"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Asistente de Accidente
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.asistenteAccidente"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    autocomplete="off"
                                    :disabled="!controlSeguimiento.informeAccidente"
                                    show-decade-nav
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.asistenteAccidente"
                                      show-decade-nav
                                      button-only
                                      size="sm"
                                      :disabled="!controlSeguimiento.informeAccidente"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <h6 class="text-primary">
                                Reporte Técnico de Accesorios
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.reporteAccesorios"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Operaciones y Bodega
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.operacionesBodega"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :disabled="!controlSeguimiento.reporteAccesorios"
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.operacionesBodega"
                                      show-decade-nav
                                      :disabled="!controlSeguimiento.reporteAccesorios"
                                      button-only
                                      size="sm"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <h6 class="text-primary">
                                Cotización de Luminaria
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.cotizacionLuminaria"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Contabilidad General
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.contabilidadGeneral"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    :disabled="!controlSeguimiento.cotizacionLuminaria"
                                    autocomplete="off"
                                    show-decade-nav
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.contabilidadGeneral"
                                      show-decade-nav
                                      button-only
                                      size="sm"
                                      :disabled="!controlSeguimiento.cotizacionLuminaria"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <h6 class="text-primary">
                                Cotización de Accesorios
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.cotizacionAccesorios"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Contrata
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.contrata"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    :disabled="!controlSeguimiento.cotizacionAccesorios"
                                    autocomplete="off"
                                    show-decade-nav
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.contrata"
                                      show-decade-nav
                                      :disabled="!controlSeguimiento.cotizacionAccesorios"
                                      button-only
                                      size="sm"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <h6 class="text-primary">
                                Certificación de Accidente
                              </h6>
                            </b-col>
                          </b-row>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-model="controlSeguimiento.certificacionAccidente"
                            class="checkbox2"
                            :disabled="editable"
                          />
                        </td>
                        <td>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group>
                                <h6 class="text-primary">
                                  Contabilidad
                                </h6>
                                <b-input-group>
                                  <b-form-input
                                    v-model="controlSeguimiento.contabilidad"
                                    type="text"
                                    placeholder="AAAA-MM-DD"
                                    autocomplete="off"
                                    :disabled="!controlSeguimiento.certificacionAccidente"
                                    show-decade-nav
                                  />
                                  <b-input-group-append>
                                    <b-form-datepicker
                                      v-model="controlSeguimiento.contabilidad"
                                      show-decade-nav
                                      :disabled="!controlSeguimiento.certificacionAccidente"
                                      button-only
                                      size="sm"
                                      right
                                      locale="en-US"
                                      aria-controls="example-input"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="guardarDetalles"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-card>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BForm,
  BCol,
  BTabs,
  BTab,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTimepicker,
  VBModal,
  BCarouselSlide, BCarousel, BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CoordinacionAseguradoraFormularioVue from '@/views/mantenimiento/coordinacionAseguradora/coordinacion-formulario.vue'
import {
  getCoordinacion,
  updateCreateCoordinacion,
} from '@/utils/mantenimiento/coordinacionAseguradora/coordinacionAseguradoraUtils'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import { getOpciones } from '@/utils/mantenimiento/opcionesGenericas'
import ComponenteVisualizacion from '@/components/mantenimiento/coordinacionAseguradora/componenteVisualizacion.vue'

export default {
  name: 'Formularioview',
  components: {
    ComponenteVisualizacion,
    BRow,
    BCol,
    BTab,
    BForm,
    BCard,
    BTabs,
    Cleave,
    BButton,
    vSelect,
    BCarousel,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BCarouselSlide,
    BFormTimepicker,
    BFormDatepicker,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    CoordinacionAseguradoraFormularioVue,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      id: router.currentRoute.params.id,
      urlImagen: '',
      item: null,
      coordinacion: {},
      datosAccidente: {
        nombre: null,
        dpi: null,
        licencia: null,
        telefono: null,
        direccion: null,
      },
      datosVehiculo: {
        placa: null,
        propietario: null,
        tipo: null,
        marca: null,
        color: null,
        modelo: null,
        seguro: null,
        aseguradora: null,
      },
      datosAdicionales: {
        matriculaPoste: null,
        codigoLampara: null,
        direccion: null,
        noCaso: null,
        corredorSeguros: {
          nombre: null,
          horaLlegada: null,
        },
        apvnsaPersonal: {
          nombre: null,
          horaLlegada: null,
        },
        luminataPersonal: {
          nombre: null,
          horaLlegada: null,
        },
      },
      options: {
        dpi: {
          numericOnly: true,
          delimiters: [' '],
          blocks: [4, 5, 4],
          uppercase: true,
        },
        clock: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
      controlSeguimiento: {
        // CheckBoxes
        fotoSeguro: false,
        informeAccidente: false,
        reporteAccesorios: false,
        cotizacionLuminaria: false,
        cotizacionAccesorios: false,
        certificacionAccidente: false,
        // DatePickers
        asistenteSeguro: '',
        asistenteAccidente: '',
        operacionesBodega: '',
        contabilidadGeneral: '',
        contrata: '',
        contabilidad: '',
      },
      seguimiento: [],
      opcionesSiNo: [],
      editableAseguradora: false,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
    }
  },
  watch: {
    'controlSeguimiento.fotoSeguro': function (newValue) {
      // Si el checkbox se desactiva, borra el valor del datepicker
      if (!newValue) {
        this.controlSeguimiento.asistenteSeguro = ''
      }
    },
    'controlSeguimiento.informeAccidente': function (newValue) {
      if (!newValue) {
        this.controlSeguimiento.asistenteAccidente = ''
      }
    },
    'controlSeguimiento.reporteAccesorios': function (newValue) {
      if (!newValue) {
        this.controlSeguimiento.operacionesBodega = ''
      }
    },
    'controlSeguimiento.cotizacionLuminaria': function (newValue) {
      if (!newValue) {
        this.controlSeguimiento.contabilidadGeneral = ''
      }
    },
    'controlSeguimiento.cotizacionAccesorios': function (newValue) {
      if (!newValue) {
        this.controlSeguimiento.contrata = ''
      }
    },
    'controlSeguimiento.certificacionAccidente': function (newValue) {
      if (!newValue) {
        this.controlSeguimiento.contabilidad = ''
      }
    },
    'datosVehiculo.seguro': function (nuevoValor) {
      const valorSeleccionado = nuevoValor ? nuevoValor.value : null

      this.editableAseguradora = valorSeleccionado === 'si'
    },
  },
  async created() {
    this.coordinacion = await getCoordinacion(this.id)
    if (this.coordinacion.datosAdicionales && this.coordinacion.datosAdicionales.length > 0) {
      this.cargarDatos()
    }
    this.opcionesSiNo = getOpciones(1)
  },
  methods: {
    async guardarDetalles() {
      const isValid = await this.validateForm()

      if (isValid) {
        await this.tabAccidente(isValid)
        await this.tabVehiculo(isValid)
        await this.tabAdicionales(isValid)
        await this.tabControl(isValid)
      }
    },
    async validateForm() {
      try {
        const success = await this.$refs.simpleRules.validate()

        if (!success) {
          mensajeError('Debe de llenar todos los datos adicionales de cada pestaña!')
        } else {
          mensajeInformativo('Actualizada', 'Coordinación Aseguradora Actualizada Correctamente!')
          this.$router.replace('/coordinacion-aseguradora')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Coordinación Aseguradora Actualizada',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Datos del Vehículo Actualizados!',
            },
          })
        }
        return success
      } catch (error) {
        console.error('Error de validación:', error)
        return false
      }
    },
    async tabAccidente(isValid) {
      if (isValid) {
        const body = { ...this.coordinacion }
        const accidente = { ...this.datosAccidente, id: 1 }

        const existeIndex = body.datosAdicionales.findIndex(item => item.id === 1)

        if (existeIndex !== -1) {
          body.datosAdicionales[existeIndex] = accidente
        } else {
          body.datosAdicionales.push(accidente)
        }
        body.estado = 'FINALIZADA'

        await updateCreateCoordinacion(body, 1)
      }
    },
    async tabVehiculo(isValid) {
      if (isValid) {
        const body = { ...this.coordinacion }
        const vehiculo = { ...this.datosVehiculo, id: 2 }

        const existeIndex = body.datosAdicionales.findIndex(item => item.id === 2)

        if (existeIndex !== -1) {
          body.datosAdicionales[existeIndex] = vehiculo
        } else {
          body.datosAdicionales.push(vehiculo)
        }
        body.estado = 'FINALIZADA'

        await updateCreateCoordinacion(body, 1)
      }
    },
    async tabAdicionales(isValid) {
      if (isValid) {
        const body = { ...this.coordinacion }
        const adicionales = { ...this.datosAdicionales, id: 3 }

        const existeIndex = body.datosAdicionales.findIndex(item => item.id === 3)

        if (existeIndex !== -1) {
          body.datosAdicionales[existeIndex] = adicionales
        } else {
          body.datosAdicionales.push(adicionales)
        }
        body.estado = 'FINALIZADA'

        await updateCreateCoordinacion(body, 1)
      }
    },
    async tabControl(isValid) {
      if (isValid) {
        const body = { ...this.coordinacion }
        const controlSeguimiento = { ...this.controlSeguimiento, id: 4 }

        const existeIndex = body.datosAdicionales.findIndex(item => item.id === 4)

        if (existeIndex !== -1) {
          body.datosAdicionales[existeIndex] = controlSeguimiento
        } else {
          body.datosAdicionales.push(controlSeguimiento)
        }
        body.estado = 'FINALIZADA'
        await updateCreateCoordinacion(body, 1)
      }
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    async cancel() {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Desea cancelar los cambios?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, seguir aquí',
        confirmButtonText: 'Sí, cancelar',
      })
      if (result.isConfirmed) {
        if (
          router.currentRoute.params.action === 'Edit'
            || router.currentRoute.params.action === 'Create'
        ) {
          this.$router.replace('/coordinacion-aseguradora')
        }
      }
    },
    cargarDatos() {
      if (this.coordinacion.datosAdicionales && this.coordinacion.datosAdicionales.length > 0) {
        // Datos del Accidente
        const primerDatoAccidente = this.coordinacion.datosAdicionales[0].nombre
        const segundoDatoAccidente = this.coordinacion.datosAdicionales[0].dpi
        const tercerDatoAccidente = this.coordinacion.datosAdicionales[0].telefono
        const cuartoDatoAccidente = this.coordinacion.datosAdicionales[0].licencia
        const quitnoDatoAccidente = this.coordinacion.datosAdicionales[0].direccion
        this.datosAccidente.nombre = primerDatoAccidente
        this.datosAccidente.dpi = segundoDatoAccidente
        this.datosAccidente.telefono = tercerDatoAccidente
        this.datosAccidente.licencia = cuartoDatoAccidente
        this.datosAccidente.direccion = quitnoDatoAccidente
        // Datos del Vehículo
        const primerDatoVehiculo = this.coordinacion.datosAdicionales[1].placa
        const segundoDatoVehiculo = this.coordinacion.datosAdicionales[1].propietario
        const tercerDatoVehiculo = this.coordinacion.datosAdicionales[1].tipo
        const cuartoDatoVehiculo = this.coordinacion.datosAdicionales[1].marca
        const quintoDatoVehiculo = this.coordinacion.datosAdicionales[1].color
        const sextoDatoVehiculo = this.coordinacion.datosAdicionales[1].modelo
        const septimoDatoVehiculo = this.coordinacion.datosAdicionales[1].seguro
        const octavoDatoVehiculo = this.coordinacion.datosAdicionales[1].aseguradora
        this.datosVehiculo.placa = primerDatoVehiculo
        this.datosVehiculo.propietario = segundoDatoVehiculo
        this.datosVehiculo.tipo = tercerDatoVehiculo
        this.datosVehiculo.marca = cuartoDatoVehiculo
        this.datosVehiculo.color = quintoDatoVehiculo
        this.datosVehiculo.modelo = sextoDatoVehiculo
        this.datosVehiculo.seguro = septimoDatoVehiculo
        this.datosVehiculo.aseguradora = octavoDatoVehiculo
        // Datos Adicionales
        const primerDatoAdicionales = this.coordinacion.posteReferencia
        const segundoDatoAdicionales = this.coordinacion.datosAdicionales[2].codigoLampara
        const tercerDatoAdicionales = this.coordinacion.datosAdicionales[2].direccion
        const cuartoDatoAdicionales = this.coordinacion.datosAdicionales[2].noCaso
        const quintoDatoAdicionales = this.coordinacion.datosAdicionales[2].corredorSeguros.nombre
        const sextoDatoAdicionales = this.coordinacion.datosAdicionales[2].corredorSeguros.horaLlegada
        const septimoDatoAdicionales = this.coordinacion.datosAdicionales[2].apvnsaPersonal.nombre
        const octavoDatoAdicionales = this.coordinacion.datosAdicionales[2].apvnsaPersonal.horaLlegada
        const novenoDatoAdicionales = this.coordinacion.datosAdicionales[2].luminataPersonal.nombre
        const decimoDatoAdicionales = this.coordinacion.datosAdicionales[2].luminataPersonal.horaLlegada
        this.datosAdicionales.matriculaPoste = primerDatoAdicionales
        this.datosAdicionales.codigoLampara = segundoDatoAdicionales
        this.datosAdicionales.direccion = tercerDatoAdicionales
        this.datosAdicionales.noCaso = cuartoDatoAdicionales
        this.datosAdicionales.corredorSeguros.nombre = quintoDatoAdicionales
        this.datosAdicionales.corredorSeguros.horaLlegada = sextoDatoAdicionales
        this.datosAdicionales.apvnsaPersonal.nombre = septimoDatoAdicionales
        this.datosAdicionales.apvnsaPersonal.horaLlegada = octavoDatoAdicionales
        this.datosAdicionales.luminataPersonal.nombre = novenoDatoAdicionales
        this.datosAdicionales.luminataPersonal.horaLlegada = decimoDatoAdicionales
        // Datos Control y Orden
        const primerDatoControl = this.coordinacion.datosAdicionales[3].fotoSeguro
        const segundoDatoControl = this.coordinacion.datosAdicionales[3].asistenteSeguro
        const tercerDatoControl = this.coordinacion.datosAdicionales[3].informeAccidente
        const cuartoDatoControl = this.coordinacion.datosAdicionales[3].asistenteAccidente
        const quintoDatoControl = this.coordinacion.datosAdicionales[3].reporteAccesorios
        const sextoDatoControl = this.coordinacion.datosAdicionales[3].operacionesBodega
        const septimoDatoControl = this.coordinacion.datosAdicionales[3].cotizacionLuminaria
        const octavoDatoControl = this.coordinacion.datosAdicionales[3].contabilidadGeneral
        const novenoDatoControl = this.coordinacion.datosAdicionales[3].cotizacionAccesorios
        const decimoDatoControl = this.coordinacion.datosAdicionales[3].contrata
        const onceavoDatoControl = this.coordinacion.datosAdicionales[3].certificacionAccidente
        const doceavoDatoControl = this.coordinacion.datosAdicionales[3].contabilidad
        this.controlSeguimiento.fotoSeguro = primerDatoControl
        this.controlSeguimiento.asistenteSeguro = segundoDatoControl
        this.controlSeguimiento.informeAccidente = tercerDatoControl
        this.controlSeguimiento.asistenteAccidente = cuartoDatoControl
        this.controlSeguimiento.reporteAccesorios = quintoDatoControl
        this.controlSeguimiento.operacionesBodega = sextoDatoControl
        this.controlSeguimiento.cotizacionLuminaria = septimoDatoControl
        this.controlSeguimiento.contabilidadGeneral = octavoDatoControl
        this.controlSeguimiento.cotizacionAccesorios = novenoDatoControl
        this.controlSeguimiento.contrata = decimoDatoControl
        this.controlSeguimiento.certificacionAccidente = onceavoDatoControl
        this.controlSeguimiento.contabilidad = doceavoDatoControl
      }
    },
  },
}
</script>

<style scoped>
.tablaa {
  width: 100%;
}
.checkbox2 {
  margin-left: -240px;
}
</style>
