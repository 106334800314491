<template>
  <div>
    <b-overlay :show="show">
      <b-card>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="6">
                <h5 class="text-primary">
                  Tipo de Accidente
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de Accidente"
                    rules="required"
                  >
                    <v-select
                      v-model="coordinacion.tipoAccidente"
                      label="nombre"
                      :options="accidentes"
                      :disabled="camposDeshabilitados"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <h5 class="text-primary">
                  Número de Poste
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Número de Poste"
                    rules="required"
                  >
                    <b-form-input
                      v-model="coordinacion.posteReferencia"
                      :state="errors.length > 0 ? false:null"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="coordinacion.tipoAccidente === null"
                cols="12"
                style="display: flex; justify-content: center"
              >
                <h2
                  style="font-weight: bold"
                  class="text-primary"
                >
                  SELECCIONA EL TIPO DE ACCIDENTE
                </h2>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row>
          <b-col cols="12">
            <form-wizard
              v-if="coordinacion.tipoAccidente !== null"
              ref="wizard"
              color="#376FA8"
              :title="null"
              :subtitle="null"
              shape="square"
              next-button-text="Siguiente"
              finish-button-text="Finalizar"
              back-button-text="Anterior"
              @on-complete="formSubmitted"
            >
              <!-- fotografias accidente tab -->
              <tab-content
                title="Tipo Accidente"
                :before-change="validationForm1"
              >
                <validation-observer
                  ref="fotoAccidente"
                  tag="form"
                >
                  <b-row v-if="coordinacion.tipoAccidente && coordinacion.tipoAccidente.id === 1">
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Nombre del Ajustador
                      </h5>
                      <b-form-group>
                        <b-form-input
                          v-model="coordinacion.nombreAjustador"
                          :disabled="camposDeshabilitados"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="mb-0">
                        Detalles de Accidente
                      </h3>
                      <h6 class="text-muted">
                        Fotografías del Accidente.
                      </h6>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía Panorámica del Accidente
                        </h5>
                        <imagen
                          :key="'fotografiaPanoramica'"
                          :leyenda="'fotografiaPanoramica'"
                          @cargar-file="fotografiaPanoramica"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía Licencia
                        </h5>
                        <imagen
                          :key="'fotografiaLicencia'"
                          :leyenda="'fotografiaLicencia'"
                          @cargar-file="fotografiaLicencia"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía del Vehiculo
                        </h5>
                        <imagen
                          :key="'fotografiaVehiculo'"
                          :leyenda="'fotografiaVehiculo'"
                          @cargar-file="fotografiaVehiculo"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía de Documentos
                        </h5>
                        <imagen
                          :key="'fotografiaDocumentos'"
                          :leyenda="'fotografiaDocumentos'"
                          @cargar-file="fotografiaDocumentos"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observación General
                        </h6>
                        <b-form-textarea
                          v-model="observacionGeneral"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-else-if="coordinacion.tipoAccidente && coordinacion.tipoAccidente.id === 2">
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Nombre del Ajustador
                      </h5>
                      <b-form-group>
                        <b-form-input
                          v-model="coordinacion.nombreAjustador"
                          :disabled="camposDeshabilitados"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Condiciones Peligrosas
                      </h3>
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <h5 class="text-primary">
                        Fotografía de los Daños a la Red
                      </h5>
                      <b-form-group>
                        <imagen
                          :key="'fotografiaDanosRed'"
                          :leyenda="'fotografiaDanosRed'"
                          @cargar-file="fotografiaDanosRed"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observación General
                        </h6>
                        <b-form-textarea
                          v-model="observacionGeneral"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- daños poste tab -->
              <tab-content
                title="Poste"
                :before-change="validationForm2"
              >
                <validation-observer
                  ref="posteTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Daños del Poste
                      </h3>
                      <h6 class="text-muted">
                        Datos del Poste.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Tipo de Poste
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tipo de Poste"
                          rules="required"
                        >
                          <v-select
                            v-model="datosPoste.tipoPoste"
                            :state="errors.length > 0 ? false:null"
                            label="nombre"
                            :options="tiposPoste"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Daño
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Daño"
                          rules="required"
                        >
                          <v-select
                            v-model="datosPoste.dañoPoste"
                            label="nombre"
                            :options="daños"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Poste
                        </h6>
                        <b-form-textarea
                          v-model="datosPoste.observacionesPoste"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía de Poste
                        </h5>
                        <imagen
                          :key="'fotografia-poste'"
                          :leyenda="'fotografia-poste'"
                          @cargar-file="imagenPoste"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- daños de brazo tab  -->
              <tab-content
                title="Brazo"
                :before-change="validationForm3"
              >
                <validation-observer
                  ref="brazoTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Daños del Brazo
                      </h3>
                      <h6 class="text-muted">
                        Datos del Brazo.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Tamaño de Brazo
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tamaño de Brazo"
                          rules="required"
                        >
                          <v-select
                            v-model="datosBrazo.tamañoBrazo"
                            label="nombre"
                            :options="tamañosBrazo"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Cantidad de Brazos
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Cantidad de Brazos"
                          rules="required"
                        >
                          <v-select
                            v-model="datosBrazo.cantidadBrazo"
                            label="nombre"
                            :options="filteredTramos([1, 2, 3])"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Daño
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Daño"
                          rules="required"
                        >
                          <v-select
                            v-model="datosBrazo.dañoBrazo"
                            label="nombre"
                            :options="daños"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Brazo
                        </h6>
                        <b-form-textarea
                          v-model="datosBrazo.observacionesBrazo"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía del Brazo
                        </h5>
                        <imagen
                          :key="'foto-brazo'"
                          :leyenda="'foto-brazo'"
                          @cargar-file="imagenBrazo"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- daños de lampara tab -->
              <tab-content
                title="Lámpara"
                :before-change="validationForm4"
              >
                <validation-observer
                  ref="lamparaTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Daños de Lámpara
                      </h3>
                      <h6 class="text-muted">
                        Datos de la Lámpara.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Cantidad de Lámparas
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Cantidad de Lámparas"
                          rules="required"
                        >
                          <v-select
                            v-model="datosLampara.cantidadLampara"
                            label="nombre"
                            :state="errors.length > 0 ? false:null"
                            :options="tramos"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Potencia de Lámpara
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Potencia de Lámpara"
                          rules="required"
                        >
                          <v-select
                            v-model="datosLampara.potenciaLampara"
                            label="nombre"
                            :state="errors.length > 0 ? false:null"
                            :options="potencias"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--b-col cols="12">
                      <h5 class="text-primary">
                        Código de la Lámpara
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Código de la Lámpara"
                          rules="required"
                        >
                          <b-form-input
                            v-model="datosLampara.codigoLampara"
                            :state="errors.length > 0 ? false:null"
                            type="number"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col-->
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Daño
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Daño"
                          rules="required"
                        >
                          <v-select
                            v-model="datosLampara.dañoLampara"
                            label="nombre"
                            :options="daños"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Lámpara
                        </h6>
                        <b-form-textarea
                          v-model="datosLampara.observacionesLampara"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía de la Lámpara
                        </h5>
                        <imagen
                          :key="'foto-lampara'"
                          :leyenda="'foto-lampara'"
                          @cargar-file="imagenLampara"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- daños de triplex tab -->
              <tab-content
                title="Triplex"
                :before-change="validationForm5"
              >
                <validation-observer
                  ref="triplexTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Daños del Triplex
                      </h3>
                      <h6 class="text-muted">
                        Datos del Triplex.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Metros Aproximados
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Metros de Triplex"
                          rules="required"
                        >
                          <v-select
                            v-model="datosTriplex.metroTriplex"
                            label="nombre"
                            :state="errors.length > 0 ? false:null"
                            :disabled="camposDeshabilitados"
                            :options="metros"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <h5 class="text-primary">
                        Cantidad de Tramos
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Cantidad de Tramos"
                          rules="required"
                        >
                          <v-select
                            v-model="datosTriplex.cantidadTriplex"
                            label="nombre"
                            :options="tramos"
                            :state="errors.length > 0 ? false:null"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <h5 class="text-primary">
                        Daños
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Daños"
                          rules="required"
                        >
                          <v-select
                            v-model="datosTriplex.dañoTriplex"
                            label="nombre"
                            :options="daños"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Triplex
                        </h6>
                        <b-form-textarea
                          v-model="datosTriplex.observacionesTriplex"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía del Triplex
                        </h5>
                        <imagen
                          :key="'foto-triplex'"
                          :leyenda="'foto-triplex'"
                          @cargar-file="imagenTriplex"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- daños de accesorios tab -->
              <tab-content
                title="Accesorios"
                :before-change="validationForm6"
              >
                <validation-observer
                  ref="accesoriosTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Daños de los Accesorios
                      </h3>
                      <h6 class="text-muted">
                        Datos de los Accesorios.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Accesorios
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Accesorios"
                          rules="required"
                        >
                          <v-select
                            v-model="datosAccesorios.accesoriosAdicionales"
                            :state="errors.length > 0 ? false:null"
                            label="nombre"
                            multiple
                            :options="accesorios"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Daños
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Daños"
                          rules="required"
                        >
                          <v-select
                            v-model="datosAccesorios.daños"
                            label="nombre"
                            :options="daños"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Accesorios
                        </h6>
                        <b-form-textarea
                          v-model="datosAccesorios.observacionesAccesorios"
                          rows="2"
                          no-resize
                          :disabled="camposDeshabilitados"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía de los Accesorios
                        </h5>
                        <imagen
                          :key="'foto-accesorios'"
                          :leyenda="'foto-accesorios'"
                          @cargar-file="imagenAccesorios"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- reconexiones tab -->
              <tab-content
                title="Reconexiones"
                :before-change="validationForm7"
              >
                <validation-observer
                  ref="reconexionesTab"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h3 class="text-primary">
                        Reconexiones
                      </h3>
                      <h6 class="text-muted">
                        Datos de las Reconexiones.
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="text-primary">
                        Cantidad de Reconexiones
                      </h5>
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Cantidad de Reconexiones"
                          rules="required"
                        >
                          <b-form-spinbutton
                            v-model="datosReconexiones.cantidadReconexiones"
                            placeholder="--"
                            class="mb-1"
                            :state="errors.length > 0 ? false:null"
                            :disabled="camposDeshabilitados"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <h6 class="text-primary">
                          Observaciones de Reconexiones
                        </h6>
                        <b-form-textarea
                          v-model="datosReconexiones.observacionesReconexiones"
                          :disabled="camposDeshabilitados"
                          rows="2"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col
                      v-if="!camposDeshabilitados"
                      cols="12"
                    >
                      <b-form-group>
                        <h5 class="text-primary">
                          Fotografía de las Reconexiones
                        </h5>
                        <imagen
                          :key="'foto-reconexiones'"
                          :leyenda="'foto-reconexiones'"
                          @cargar-file="fotografiaReconexiones"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCard, BCol, BFormGroup, BFormInput, BForm, BOverlay, BFormTextarea, BFormSpinbutton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getPoste } from '@/utils/postes'
import { saveImagen } from '@/utils/imagenes'
import { getlistado } from '@/utils/catalogos'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getListadoDetalle } from '@/utils/detallePunto'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { getListadoAccesorios } from '@/utils/accesorios'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getListadoMantenimiento } from '@/utils/mantenimiento/mantenimiento'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { updateCreateCoordinacion } from '@/utils/mantenimiento/coordinacionAseguradora/coordinacionAseguradoraUtils'

export default {
  components: {
    Imagen,
    BRow,
    BCol,
    BCard,
    BForm,
    vSelect,
    BOverlay,
    TabContent,
    BFormGroup,
    BFormInput,
    FormWizard,
    BFormTextarea,
    BFormSpinbutton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    cargarCoordinacion: {
      type: Object,
    },
    camposDeshabilitados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      tipo: null,
      show: false,
      posteId: router.currentRoute.params.poste,
      usuario: JSON.parse(localStorage.getItem('userData')),
      coordinacion: {
        fechaCreacion: new Date(),
        posteReferencia: '',
        poste: '',
        tipoAccidente: null,
        nombreAjustador: null,
        estado: 'INGRESADA',
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        detalleAccidente: [],
        datosAdicionales: [],
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).company.id,
      },
      observacionGeneral: '',
      datosPoste: {
        poste: '',
        tipoPoste: '',
        tamañoPoste: '',
        dañoPoste: '',
        observacionesPoste: '',
        foto: null,
      },
      datosBrazo: {
        poste: '',
        cantidadBrazo: '',
        tamañoBrazo: '',
        dañoBrazo: '',
        observacionesBrazo: '',
        foto: null,
      },
      datosLampara: {
        poste: '',
        cantidadLampara: '',
        potenciaLampara: '',
        codigoLampara: '',
        dañoLampara: '',
        observacionesLampara: '',
        foto: null,
      },
      datosTriplex: {
        poste: '',
        cantidadTriplex: '',
        metroTriplex: '',
        dañoTriplex: '',
        observacionesTriplex: '',
        foto: null,
      },
      datosAccesorios: {
        poste: '',
        accesoriosAdicionales: [],
        daños: '',
        observacionesAccesorios: '',
        foto: null,
      },
      datosReconexiones: {
        poste: '',
        cantidadReconexiones: '',
        observacionesReconexiones: '',
        foto: null,
      },
      // Daños Luminaria
      fotoDañosPoste: '',
      fotoDañosBrazo: '',
      fotoDañosLampara: '',
      fotoDañosTriplex: '',
      fotoDañosAccesorios: '',
      fotoReconexiones: '',
      // Fotos Vehiculo
      fotoPanoramicaAccidente: '',
      fotoLicencia: '',
      fotoVehiculo: '',
      fotoDocumentos: '',
      fotoDanosRed: '',
      // catalogos
      accidentes: [],
      tiposPoste: [],
      tamañosBrazo: [],
      accesorios: [],
      daños: [],
      dañosRed: [],
      potencias: [],
      cantidad: [],
      tramos: [],
      metros: [],
    }
  },
  watch: {
    'coordinacion.tipoAccidente': function (newTipo, oldTipo) {
      // Se ejecuta cuando cambia el valor de coordinacion.tipoAccidente
      if (oldTipo !== null) {
        // Limpiar campos y fotos solo si había una opción previa seleccionada
        this.coordinacion.nombreAjustador = ''
        this.fotoLicencia = ''
        this.fotoVehiculo = ''
        this.fotoDocumentos = ''
        this.fotoDanosRed = ''
      }
    },
  },
  async created() {
    this.tiposPoste = await getlistado(1, 'Tipos de Poste', false, this.usuario)
    this.tamañosBrazo = await getlistado(14, 'Tipo Brazo', false, this.usuario)
    this.poste = await getPoste(this.posteId)
    if (!this.camposDeshabilitados) {
      this.coordinacion.posteReferencia = this.poste.numeroPoste
    }
    this.coordinacion.poste = this.poste
    this.datosPoste.poste = this.poste.numeroPoste
    this.datosBrazo.poste = this.poste.numeroPoste
    this.datosLampara.poste = this.poste.numeroPoste
    this.datosTriplex.poste = this.poste.numeroPoste
    this.datosAccesorios.poste = this.poste.numeroPoste
    this.datosReconexiones.poste = this.poste.numeroPoste
    this.tiposBrazo = getListadoDetalle(5)
    this.tecnologiasLampara = getListadoDetalle(8)
    this.daños = getListadoMantenimiento(1)
    this.accesorios = getListadoAccesorios(1)
    this.potencias = getListadoAccesorios(2)
    this.tramos = getListadoAccesorios(3)
    this.metros = getListadoAccesorios(4)
    this.accidentes = getListadoMantenimiento(2)
    if (this.cargarCoordinacion) {
      this.cargarDatos()
    }
  },
  methods: {
    validatePhotoForFenomenoNatural() {
      if (this.camposDeshabilitados) {
        return true
      }
      if (this.coordinacion.tipoAccidente && this.coordinacion.tipoAccidente.id === 2) { // 2 es el valor para "Fenómeno Natural"
        // Verificar si la fotografía no ha sido cargada
        if (!this.fotoDanosRed || this.fotoDanosRed === '') {
          // Mostrar un mensaje de error (puedes usar alguna librería de notificaciones)
          mensajeError('Debe Ingresar una Fotografía Panorámica del Fenómeno Natural!')
          return false // No permite avanzar al siguiente paso
        }
      } else if (this.coordinacion.tipoAccidente && this.coordinacion.tipoAccidente.id === 1) {
        if (!this.fotoPanoramicaAccidente || this.fotoPanoramicaAccidente === '') {
          mensajeError('Debe tomar una fotografía panorámica del accidente.')
          return false
        }
        if (!this.fotoLicencia || this.fotoLicencia === '') {
          mensajeError('Debe tomar una fotografía de la licencia.')
          return false
        }
        if (!this.fotoVehiculo || this.fotoVehiculo === '') {
          mensajeError('Debe tomar una fotografía del vehiculo.')
          return false
        }
        if (!this.fotoDocumentos || this.fotoDocumentos === '') {
          mensajeError('Debe tomar una fotografía de los documentos.')
          return false
        }
      }
      return true
    },
    cargarDatos() {
      // Llena el objeto formularioData con los datos de la coordinación
      this.coordinacion.tipoAccidente = this.cargarCoordinacion.tipoAccidente
      this.coordinacion.posteReferencia = this.cargarCoordinacion.posteReferencia
      this.coordinacion.nombreAjustador = this.cargarCoordinacion.nombreAjustador
      if (this.cargarCoordinacion.detalleAccidente && this.cargarCoordinacion.detalleAccidente.length > 0) {
        // Datos Poste
        const primerDetallePoste = this.cargarCoordinacion.detalleAccidente[0].tipoPoste
        const segundoDetallePoste = this.cargarCoordinacion.detalleAccidente[0].dañoPoste
        const tercerDetallePoste = this.cargarCoordinacion.detalleAccidente[0].observacionesPoste
        this.datosPoste.tipoPoste = { ...primerDetallePoste }
        this.datosPoste.dañoPoste = { ...segundoDetallePoste }
        this.datosPoste.observacionesPoste = tercerDetallePoste
        // Datos Brazo
        const primerDetalleBrazo = this.cargarCoordinacion.detalleAccidente[1].tamañoBrazo
        const segundoDetalleBrazo = this.cargarCoordinacion.detalleAccidente[1].dañoBrazo
        const tercerDetalleBrazo = this.cargarCoordinacion.detalleAccidente[1].observacionesBrazo
        this.datosBrazo.tamañoBrazo = { ...primerDetalleBrazo }
        this.datosBrazo.dañoBrazo = { ...segundoDetalleBrazo }
        this.datosBrazo.observacionesBrazo = tercerDetalleBrazo
        // Datos Lámpara
        const primerDetalleLampara = this.cargarCoordinacion.detalleAccidente[2].cantidadLampara
        const segundoDetalleLampara = this.cargarCoordinacion.detalleAccidente[2].potenciaLampara
        const tercerDetalleLampara = this.cargarCoordinacion.detalleAccidente[2].codigoLampara
        const cuartoDetalleLampara = this.cargarCoordinacion.detalleAccidente[2].dañoLampara
        const quintoDetalleLampara = this.cargarCoordinacion.detalleAccidente[2].observacionesLampara
        this.datosLampara.cantidadLampara = primerDetalleLampara
        this.datosLampara.potenciaLampara = segundoDetalleLampara
        this.datosLampara.codigoLampara = tercerDetalleLampara
        this.datosLampara.dañoLampara = { ...cuartoDetalleLampara }
        this.datosLampara.observacionesLampara = quintoDetalleLampara
        // Datos Triplex
        const primerDetalleTriplex = this.cargarCoordinacion.detalleAccidente[3].cantidadTriplex
        const segundoDetalleTriplex = this.cargarCoordinacion.detalleAccidente[3].metroTriplex
        const tercerDetalleTriplex = this.cargarCoordinacion.detalleAccidente[3].dañoTriplex
        const cuartoDetalleTriplex = this.cargarCoordinacion.detalleAccidente[3].observacionesTriplex
        this.datosTriplex.cantidadTriplex = primerDetalleTriplex
        this.datosTriplex.metroTriplex = segundoDetalleTriplex
        this.datosTriplex.dañoTriplex = { ...tercerDetalleTriplex }
        this.datosTriplex.observacionesTriplex = cuartoDetalleTriplex
        // Datos Accesorios
        const primerDetalleAccesorios = this.cargarCoordinacion.detalleAccidente[4].accesoriosAdicionales
        const segundoDetalleAccesorios = this.cargarCoordinacion.detalleAccidente[4].observacionesAccesorios
        this.datosAccesorios.accesoriosAdicionales = [...primerDetalleAccesorios]
        this.datosAccesorios.observacionesAccesorios = segundoDetalleAccesorios
        // Datos Reconexiones
        const primerDetalleReconexiones = this.cargarCoordinacion.detalleAccidente[5].cantidadReconexiones
        const segundoDetalleReconexiones = this.cargarCoordinacion.detalleAccidente[5].observacionesReconexiones
        this.datosReconexiones.cantidadReconexiones = primerDetalleReconexiones
        this.datosReconexiones.observacionesReconexiones = segundoDetalleReconexiones
      }
    },
    filteredTramos(filterOptions) {
      // eslint-disable-next-line radix
      return this.tramos.filter(tramo => filterOptions.includes(parseInt(tramo.nombre)))
    },
    async formSubmitted() {
      if (this.camposDeshabilitados) {
        mensajeError('Esto es una Visualización del Formulario y no es Posible Modificarlo!')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: 'Esto es una Visualización de los datos ingresados.',
          },
        })
      } else {
        this.show = true
        this.coordinacion.usuarioCreacion = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
          telefono: '',
        }

        const body = { ...this.coordinacion }

        body.postes = []
        body.postes.push({ ...this.poste })
        body.observacionGeneral = this.observacionGeneral

        const primerObjetoDetalle = {
          datosPoste: { ...this.datosPoste, foto: null },
          datosBrazo: { ...this.datosBrazo, foto: null },
          datosLampara: { ...this.datosLampara, foto: null },
          datosTriplex: { ...this.datosTriplex, foto: null },
          datosAccesorios: { ...this.datosAccesorios, foto: null },
          datosReconexiones: { ...this.datosReconexiones, foto: null },
        }
        body.detalleAccidente.push(primerObjetoDetalle)

        const item = await updateCreateCoordinacion(body, 2)

        if (!item) {
          mensajeError('Inconvenientes almacenando la coordinación!, intente nuevamente!')
          this.show = false
          return
        }

        const urlBucket = `coordinacion-aseguradoras/${item.id}`
        const isMobile = window.screen.width <= 760

        if (this.coordinacion.tipoAccidente.id === 1) {
          const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaAccidente, 'Fotografía del Accidente', this.coordinacion.posteReferencia, this.coordinacion.usuarioCreacion, isMobile)
          if (imagen1) item.seguimiento.push(imagen1)
          const imagen2 = await saveImagen(urlBucket, this.localization, this.fotoLicencia, 'Fotografía Licencia', this.coordinacion.posteReferencia, this.coordinacion.usuarioCreacion, isMobile)
          if (imagen2) item.seguimiento.push(imagen2)
          const imagen3 = await saveImagen(urlBucket, this.localization, this.fotoVehiculo, 'Fotografía Vehiculo', this.coordinacion.posteReferencia, this.coordinacion.usuarioCreacion, isMobile)
          if (imagen3) item.seguimiento.push(imagen3)
          const imagen4 = await saveImagen(urlBucket, this.localization, this.fotoDocumentos, 'Fotografía Documentos', this.coordinacion.posteReferencia, this.coordinacion.usuarioCreacion, isMobile)
          if (imagen4) item.seguimiento.push(imagen4)
        } else if (this.coordinacion.tipoAccidente.id === 2) {
          const imagen6 = await saveImagen(urlBucket, this.localization, this.fotoDanosRed, 'Fotografía de los Daños a la Red', this.coordinacion.posteReferencia, this.coordinacion.usuarioCreacion, isMobile)
          if (imagen6) item.seguimiento.push(imagen6)
        }

        item.detalleAccidente[0].datosPoste.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosPoste, 'Fotografía Daños al Poste', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)
        item.detalleAccidente[0].datosBrazo.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosBrazo, 'Fotografía Daños al Brazo', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)
        item.detalleAccidente[0].datosLampara.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosLampara, 'Fotografía Daños a la Lámpara', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)
        item.detalleAccidente[0].datosTriplex.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosTriplex, 'Fotografía Daños al Triplex', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)
        item.detalleAccidente[0].datosAccesorios.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosAccesorios, 'Fotografía Daños a los Accesorios', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)
        item.detalleAccidente[0].datosReconexiones.foto = await saveImagen(urlBucket, this.localization, this.fotoReconexiones, 'Fotografía de las Reconexiones', 'POSTE 1', this.coordinacion.usuarioCreacion, isMobile)

        await updateCreateCoordinacion(item, 1)

        mensajeInformativo('Guardada', 'Coordinación Aseguradora Almacenada Correctamente!')
        this.$router.replace('/coordinacion-aseguradora')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Coordinación Aseguradora Almacenada',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Coordinación Aseguradora Almacenada Correctamente!',
              },
            })
          })
        this.show = false
      }
    },
    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.fotoAccidente.validate().then(success => {
          if (success && this.validatePhotoForFenomenoNatural()) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationForm2() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.posteTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoDañosPoste || this.fotoDañosPoste === '') {
            mensajeError('Debe Ingresar una Fotografía del Poste!')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    validationForm3() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.brazoTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoDañosBrazo || this.fotoDañosBrazo === '') {
            mensajeError('Debe Ingresar una Fotografía del Brazo')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    validationForm4() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.lamparaTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoDañosLampara || this.fotoDañosLampara === '') {
            mensajeError('Debe Ingresar una Fotografía de la Lámpara')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    validationForm5() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.triplexTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoDañosTriplex || this.fotoDañosTriplex === '') {
            mensajeError('Debe Ingresar una Fotografía de el Triplex')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    validationForm6() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.accesoriosTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoDañosAccesorios || this.fotoDañosAccesorios === '') {
            mensajeError('Debe Ingresar una Fotografía de los Accesorios')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    validationForm7() {
      if (this.camposDeshabilitados) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        this.$refs.reconexionesTab.validate().then(success => {
          if (!success) {
            reject()
          } else if (!this.fotoReconexiones || this.fotoReconexiones === '') {
            mensajeError('Debe Ingresar una Fotografía de la Reconexión')
            reject()
          } else {
            resolve(true)
          }
        })
      })
    },
    imagenPoste(file) {
      this.fotoDañosPoste = file
    },
    imagenBrazo(file) {
      this.fotoDañosBrazo = file
    },
    imagenLampara(file) {
      this.fotoDañosLampara = file
    },
    imagenTriplex(file) {
      this.fotoDañosTriplex = file
    },
    imagenAccesorios(file) {
      this.fotoDañosAccesorios = file
    },
    fotografiaDanosRed(file) {
      this.fotoDanosRed = file
    },
    fotografiaPanoramica(file) {
      this.fotoPanoramicaAccidente = file
    },
    fotografiaLicencia(file) {
      this.fotoLicencia = file
    },
    fotografiaVehiculo(file) {
      this.fotoVehiculo = file
    },
    fotografiaDocumentos(file) {
      this.fotoDocumentos = file
    },
    fotografiaReconexiones(file) {
      this.fotoReconexiones = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.wizard-nav.wizard-nav-pills {
  display: none;
}
</style>
